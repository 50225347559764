import React from "react";

import { About, Footer, Header, Skills, Testimonial, Work } from "./container";
import { Navbar } from "./components";
import "./App.scss";
import Starfield from "react-starfield";

const App = () => (
  <div className="app">
    <Starfield
      starCount={5000}
      starColor={[153, 211, 223]}
      speedFactor={0.05}
      backgroundColor="#181d20"
    />
    <Navbar />
    <Header />
    <About />
    <Work />
    <Skills />
    <Testimonial />
    <Footer />
  </div>
);

export default App;
