import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  // projectId: "wrrwvr2u",
  dataset: "production",
  apiVersion: "2022-02-01",
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
  // token:
  //   "sknK5RzlGOVLZejECrNYdJGHNaOVuc16eYpzaP8hx7qXUoQpnxPEwe7mngSe5PHoMHZyH7AXO6Q3vEg8B9WIPaIkOv5TnyTkXQfAJd15X1CuHDyNoUKfsozvXqqLgjDbLrImmZVWYyTKICE5YGbw2jt3jbmB5sjKRMz9DIJPvB7OiUdQWyRR",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
